import React, { useEffect } from 'react';
import $ from 'jquery';
import "./google-search.scss"
import SearchBox from './SearchBox';

function GoogleSearch() {
  useEffect(() => {
    $('.mobile-search-toggle').on('click', function(event) {
      $('.search-block').show();
      $('.mobile-close-search.close-search-icon').show();
    });
    $('.mobile-close-search.close-search-icon').on('click', function(event) {
      $('.search-block').hide();
      $('.mobile-close-search.close-search-icon').hide();
    });
    $(window).on('resize', function(e) {
      if (($(window).width() > 991)) {
        $('.search-block').show();
      }
    });

    let observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        mutation.addedNodes.forEach(function(node) {
          if (node.classList && node.classList.contains('gsc-search-box')) {
            const inputField = document.getElementById('gsc-i-id1');
            const clearButton = document.querySelector('.gsst_a');
            inputField.placeholder = 'Search...'

            if (window.innerWidth > 991) {
              inputField.addEventListener('input', toggleVisibility);
              clearButton.addEventListener('click', toggleVisibility);
            }

            observer.disconnect();
          }
        });
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });

    function toggleVisibility() {
      const inputField = document.getElementById('gsc-i-id1');
      if (inputField.value === '') {
        $('.gsc-search-button.gsc-search-button-v2').show();
      } else {
        $('.gsc-search-button.gsc-search-button-v2').hide();
      }
    }
  }, []);

  return (
    <div>
      <div className="mobile-search-toggle search-icon"> </div>
      <div className="mobile-close-search close-search-icon"></div>
      <div className="search-block">
        <SearchBox />
      </div>
    </div>
  );
}

export default GoogleSearch;
