import React, { useEffect, useState } from "react";
import './styles.scss'
import { useCollapse } from 'react-collapsed';
import { useTranslation } from 'react-i18next';


const Recommendations = ({userGroup, chartName}) => {
    const [group, setGroup] = useState('very_low_and_low');

    useEffect(() => {
        switch(userGroup) {
            case 'very_low':
                return setGroup('very_low_and_low');
            case 'low':
                return setGroup('very_low_and_low');
            case 'below_average':
                return setGroup('below_average_and_average');
            case 'average':
                return setGroup('below_average_and_average');
            case 'good_division':
                return setGroup('good_and_above_average');
            case 'above_average':
                return setGroup('good_and_above_average');
            case 'great':
                return setGroup('great_and_excellent');
            case 'excellent':
                return setGroup('great_and_excellent');
        }
    }, [userGroup]);

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
    const { t, i18n } = useTranslation();
    return (
        <div className="collapsible">
            <div >
                <div className="header" {...getToggleProps()}>
                    {isExpanded ? (<p style={{fontSize: 16, fontWeight: 500, color: '#000000'}}>Recommendations<i className="arrow up"></i></p>) : (<p style={{fontSize: 16, fontWeight: 500, color: '#000000'}}>Recommendations<i className="arrow down"></i></p>)}
                </div>
                <div {...getCollapseProps()}>
                    <div className="body">
                        <div className="recommendations-container">
                            <div className="recommendation-header">
                                <p className="title">{t(`${chartName}.recommendations.${group}.header`)}</p>
                            </div>
                            <div className="recommendation-section">
                                {Array.from({ length: parseInt(t(`${chartName}.recommendations.${group}.question_size`)) }, (_, index) => (
                                    <div className="recommendation-item" key={index}>
                                        <p className="question-title">{t(`${chartName}.recommendations.${group}.${index + 1}.title`)}</p>
                                        <p>{t(`${chartName}.recommendations.${group}.${index + 1}.body`)}</p>
                                    </div>
                                ))}
                            </div>
                            <div className="recommendation-footer">
                                <p>{t(`${chartName}.recommendations.${group}.footer`)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Recommendations;