import { takeEvery, put, call  } from "redux-saga/effects";

import { GET_ORGANIC_TRAFFIC } from "./actionTypes";

import {
    getOrganicTrafficSuccess,
    getOrganicTrafficFail,
} from "./actions"

import { getOrganicTraffic } from "../../helpers/fakebackend_helper";

function* fetchOrganicTraffic() {
    try {
        const response = yield call(getOrganicTraffic)
        yield put(getOrganicTrafficSuccess(response))
    } catch (error) {
        yield put(getOrganicTrafficFail(error))
    }
}

function* organicTrafficSaga() {
    yield takeEvery(GET_ORGANIC_TRAFFIC, fetchOrganicTraffic);
}

export default organicTrafficSaga;