import React, { useEffect, useState } from "react";
import {ModalHeader, Form, Modal} from "reactstrap";
import "./styles.scss"
//redux
import { useDispatch } from "react-redux";

import {useFormik} from "formik";

//import action
import { postCreateMySettings } from "../../store/actions";
import {get} from "../../helpers/api_helper";
import {INITIAL_FILTER_SETTINGS} from "../../helpers/url_helper";
import {employeesNumberOptions, avgHourlyRateOptions, rateRefactor, employeesRefactor} from "../../helpers/select_options_helper";
import Dropdown from "./Dropdown";

const styles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: 'white',
        color: 'gray'
    }),
};

const MySettings = () => {
    const [subscribemodal, setSubscribemodal] = useState(false);
    const [mainServiceLine, setMainServiceLine] = useState("");
    const [employeesNumber, setEmployeesNumber] = useState("");
    const [avgHourlyRate, setAvgHourlyRate] = useState("");
    const [serviceLineOptions, setServiceLineOptions] = useState("");

    const dispatch = useDispatch();

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            main_service_line: mainServiceLine,
            employees_number: employeesNumber.value,
            avg_hourly_rate: avgHourlyRate.value,
        },

        onSubmit: (values) => {
            dispatch(postCreateMySettings(values));
            setSubscribemodal(false);
            mySettingFlag()
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await get(INITIAL_FILTER_SETTINGS);
                if(response.data.my_settings === false) {
                    setTimeout(() => {
                        setSubscribemodal(true);
                    }, 500);
                }
                setMainServiceLine(response.data.selected_filter_values.line);
                setAvgHourlyRate(rateRefactor(response.data.selected_filter_values.rate));
                setEmployeesNumber(employeesRefactor(response.data.selected_filter_values.employees));
                if(response.data.filter_options) {
                    setServiceLineOptions(response.data.filter_options.value.line);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        };

        fetchData();
    }, []);

    const onChangeServiceLine = selected => {
        setMainServiceLine(selected.value);
    };

    const onChangeEmployeesNumber = selected => {
        setEmployeesNumber(selected);
    };

    const onChangeAvgHourlyRate = selected => {
        setAvgHourlyRate(selected);
    };

    const mySettingFlag = () => {
        let mySettingDiv = document.getElementById('my-setting-modal')
        let filtersContainer = document.getElementById('filters_block');
        filtersContainer.removeChild(mySettingDiv);
    }

    const openModal = () => {
        setSubscribemodal(true)
    }

    return (
   <div>
        <Modal
            isOpen={subscribemodal}
            role="dialog"
            autoFocus={true}
            centered
            data-toggle="modal"
            toggle={() => {
                setSubscribemodal(!subscribemodal);
            }}
        >
            <div>
                <ModalHeader
                    className="border-bottom-0"
                    toggle={() => {
                        setSubscribemodal(!subscribemodal);
                    }}
                ></ModalHeader>
            </div>
            <div className="modal-body">
                <div className="text-center mb-4">
                    <div className="row justify-content-center">
                        <div className="col-xl-10" style={{ textAlign: 'left' }}>
                            <h4 className="main-settings-header">Main settings</h4>
                            <p className="font-size-14 mb-4 main-settings-text">
                                Please specify your company's Main service line and check if the values you set are correct.
                                Based on this data, we will be able to provide
                                you with more relevant recommendations.
                            </p>
                            <Form
                                className="form-horizontal"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                    return false;
                                }}
                            >
                                <div className="col-sm-auto" style={{ paddingBottom: 15 }}>
                                    <label htmlFor="autoSizingSelect">Main service line</label>
                                    <Dropdown
                                        name="main_service_line"
                                        value={mainServiceLine}
                                        placeholder="Select main service line"
                                        options={serviceLineOptions}
                                        onChange={onChangeServiceLine}
                                        isSearchable={false}
                                        required
                                    />
                                </div>

                                <div className="col-sm-auto" style={{ paddingBottom: 15 }}>
                                    <label htmlFor="autoSizingSelect">Hourly rate</label>
                                    <Dropdown
                                        name="avg_hourly_rate"
                                        value={avgHourlyRate}
                                        placeholder="Select hourly rate"
                                        options={avgHourlyRateOptions}
                                        onChange={onChangeAvgHourlyRate}
                                        isSearchable={false}
                                        required
                                    />
                                </div>

                                <div className="col-sm-auto" style={{ paddingBottom: 15 }}>
                                    <label htmlFor="autoSizingSelect">Employees</label>
                                    <Dropdown
                                        name="employees_number"
                                        value={employeesNumber}
                                        placeholder="Select employees number"
                                        options={employeesNumberOptions}
                                        onChange={onChangeEmployeesNumber}
                                        isSearchable={false}
                                        required
                                    />
                                </div>
                                <div className="mb-3 pt-0">
                                    <input className="btn btn-primary w-md" style={{ backgroundColor: '#DB4746', borderColor: '#DB4746' }} value="Confirm" type="submit"/>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
        <div className="default_settings_btn">
            <p className="default_settings_btn_text" onClick={openModal}>Change default settings</p>
        </div>
    </div>
    );
};

export default MySettings;
