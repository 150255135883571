import PropTypes from "prop-types";
import React, {useEffect} from "react";

//i18n
import { withTranslation } from "react-i18next";
import {getForAuth} from "../../helpers/api_helper";
import {EXTERNAL_LOGIN} from "../../helpers/url_helper";

const ExternalLogin = props => {

    useEffect(() => {
        externalLogin();
    }, []);

    const externalLogin = async () => {
        let url_string = window.location.href;
        let url = new URL(url_string);
        let token = url.searchParams.get("token");

        try {
            if(token) {
                const response = await getForAuth(EXTERNAL_LOGIN + `?token=${token}`);
                localStorage.removeItem("authUser");
                localStorage.setItem("authUser", response.headers.authorization);
                window.location.replace('/analytics');
            }
            else {
                window.location.replace('/login');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    //meta title
    document.title = "Techreviewer.co – Analytics Benchmarks";

    return (
        <React.Fragment>
            <div className="page-content"></div>
        </React.Fragment>
    );
};

ExternalLogin.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(ExternalLogin);