import {
    GET_ORGANIC_TRAFFIC,
    GET_ORGANIC_TRAFFIC_SUCCESS,
    GET_ORGANIC_TRAFFIC_FAIL,
} from "./actionTypes";

export const getOrganicTraffic = () => ({
    type: GET_ORGANIC_TRAFFIC,
});

export const getOrganicTrafficSuccess = data => ({
    type: GET_ORGANIC_TRAFFIC_SUCCESS,
    payload: data,
});

export const getOrganicTrafficFail = error => ({
    type: GET_ORGANIC_TRAFFIC_FAIL,
    payload: error,
});