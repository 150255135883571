import React, { Component } from 'react';
import "./header_layout.scss"
import './styles.scss'
import logoSvg from './techReviewerLogo.svg';
import arrowSvg from './shevronDown.svg';
import arrowTopRatingsSvg from './shevronDownTopRatings.svg';
import SvgIcons from './mobile_icons.js';
import GoogleSearch from './GoogleSearch';
import "./google-search.scss";
import { headerButtonsConfig } from "./headerConstants";
import DropdownMenu from 'components/Common/DropdownMenu';

class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true,
      showDropdownAbout: false,
      showDropdownService: false,
      showProfileMenu: false,
      showDropdownProfile: false,
      isHoverDropdownOpen: false,
      hoveredMenuItem: null,
    };

    this.profileButtonRef = React.createRef();
  }

  handleOpenDropdown = (e) => {
    this.setState({ isHoverDropdownOpen: true });
  };

  handleCloseDropdown = (e) => {
    if (this.state.isHoverDropdownOpen) {
      this.setState({ isHoverDropdownOpen: false });
    }
  };

  toggleCollapse = () => {
    this.setState((prevState) => ({
      isCollapsed: !prevState.isCollapsed,
    }));
  };

  toggleDropdown = (menu) => {
    this.setState((prevState) => ({
      [menu]: !prevState[menu],
    }));
  };

  toggleProfileMenu = () => {
    this.setState((prevState) => ({
      showProfileMenu: !prevState.showProfileMenu,
    }));
  };

  handleMenuItemHover = (menuItem) => {
    this.setState({ hoveredMenuItem: menuItem });
  };
  
  handleMenuItemLeave = () => {
    this.setState({ hoveredMenuItem: null });
  };

  render() {
    const { isCollapsed, showDropdownAbout, showDropdownService, showDropdownProfile, showProfileMenu } = this.state;
    const currentPath = window.location.pathname;

    const Logo = () => {
      return (
        <div>
          <img 
            src={logoSvg}
            alt="Logo" 
            className="logo"
            style={{
              width: '176px',
              paddingTop: '31px'
            }}
          />
        </div>
      );
    }

    const Arrow = () => {
      return (
        <img 
          src={arrowSvg}
          style={{
            width: '20px',
            height: '20px',
          }}
        />
      );
    }

    return (
      <header className="page-header no-print" onMouseLeave={this.handleCloseDropdown} data-collapse="small" data-animation="default" data-duration="400" data-w-id="3e665832-e661-9678-7e97-efacb32f4091" role="banner">
        <div className="wrapper page-header__wrapper">
          <a href="https://techreviewer.co/" aria-current="page">
            <Logo />
          </a>
          <GoogleSearch />
          <ul className="header-nav">
          <li className="header-nav__item" onMouseEnter={this.handleOpenDropdown}>
          <details
            open={this.state.isHoverDropdownOpen}
            className="header-nav__drop">
            <summary className="header-nav__drop-button">
              <span className="header-nav__drop-opener">
                <a className="header-nav__link">Top Ratings</a>
              </span>
              <img 
                src={arrowTopRatingsSvg}
                style={{
                  width: '12px',
                  height: '12px',
                  marginLeft: '8px',
                  marginRight: '8px',
                }}
              />
            </summary>
            <div className="header-nav__drop-content">
              <ul className="header-subnav">
                {headerButtonsConfig.map((listing) => (
                  <li className="header-subnav__item" key={listing.name}>
                    {listing.categories ? (
                      <DropdownMenu linkHref={listing.link} linkName={listing.name} categories={listing.categories} key={listing.link} />
                    ) : (
                      <a className="header-subnav__link" href={listing.link} key={listing.link}>
                        {listing.name}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </details>
          </li>
            <li className="header-nav__item">
              <a className="header-nav__link" href={process.env.REACT_APP_TECHREVIEWER_URL + '/case-studies'}>
                Industry Showcase
              </a>
            </li>
            <li className="header-nav__item">
              <a className="header-nav__link" href={process.env.REACT_APP_TECHREVIEWER_URL + '/blog'}>
                Blog
              </a>
            </li>
            <li className="header-nav__item">
              <div className={`profile-button-container ${showProfileMenu ? 'active' : ''}`}>
                <a
                  aria-current="page"
                  className="profile-button"
                  onClick={this.toggleProfileMenu}
                  ref={this.profileButtonRef}
                >
                  Profile
                </a>
                {showProfileMenu && (
                  <div className="profile-menu-button">
                    <div className="profile-menu-button-content">
                      <ul className="profile-button-menu">
                        <li>
                          <a className="profile-menu-a" href={process.env.REACT_APP_TECHREVIEWER_URL + '/owners/profile'}>Profile info</a>
                        </li>
                        <li>
                          <a className="profile-menu-a" href={process.env.REACT_APP_TECHREVIEWER_URL + '/owners/company'}>Edit company page</a>
                        </li>
                        <li>
                          <a className="profile-menu-a" href='/analytics'>Analytics benchmarks</a>
                        </li>
                        {currentPath !== '/login' && (
                          <li>
                            <a className="profile-menu-a" href="/logout">Sign out</a>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            </li>
          </ul>
          <nav className="mobile-nav">
            <button className={`mobile-nav__toggler ${isCollapsed ? 'collapsed' : ''}`} style={{ float: 'right' }} type="button" onClick={this.toggleCollapse}>
              <span className="mobile-nav__toggler-indicator"></span>
            </button>
            <div className={`collapse mobile-nav__dropdown ${isCollapsed ? '' : 'show'}`} id="collapseExample">
              <div className="mobile-nav__content">
                <div>
                  <div>
                    <div>
                      <a className="mobile-nav__item" href={process.env.REACT_APP_TECHREVIEWER_URL + '/case-studies'}>
                      Industry Showcase
                      </a>
                    </div> 
                    <div className="mobile-nav__drop-1">
                      <details className="mobile-nav__drop-1-content">
                        <summary className="mobile-nav__drop-1-opener">
                          <span className="mobile-nav__drop-n-opener-icon">
                            <Arrow />
                          </span>
                        </summary>
                        <ul className="mobile-nav__drop-n-list">
                          {headerButtonsConfig.map((listing) => (
                            <li className="mobile-nav__drop-2" key={listing.id}>
                              {listing.categories && (
                                <details className="mobile-nav__drop-2-content">
                                  <summary className="mobile-nav__drop-2-opener">
                                    <span className="mobile-nav__drop-n-opener-icon">
                                      <Arrow />
                                    </span>
                                  </summary>
                                  <div className="mobile-nav__drop-n-list">
                                    {listing.categories.map((category) => (
                                      <div className="mobile-nav__drop-3" key={category.id}>
                                        <details className="mobile-nav__drop-3-content">
                                          <summary className="mobile-nav__drop-3-opener">
                                            <span className="mobile-nav__drop-n-opener-icon">
                                              <Arrow />
                                            </span>
                                          </summary>
                                          <ul className="mobile-nav__drop-n-list">
                                            {category.list.map((categoryItem) => (
                                              <li className="mobile-nav__drop-n-item" key={categoryItem.id}>
                                                <a className="mobile-nav__drop-n-link" href={categoryItem.link}>
                                                  {categoryItem.text}
                                                </a>
                                              </li>
                                            ))}
                                          </ul>
                                        </details>
                                        <div className="mobile-nav__drop-3-text">
                                            <strong className="mobile-nav__drop-n-text">{category.title}</strong>
                                          </div>
                                      </div>
                                    ))}
                                  </div>
                                </details>
                              )}
                              <div className="mobile-nav__drop-2-link">
                                <a className="mobile-nav__drop-n-link" href={listing.link}>
                                  {listing.name}
                                </a>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </details>
                      <div className="mobile-nav__drop-1-link">
                        <a className="mobile-nav__item" href={process.env.REACT_APP_TECHREVIEWER_URL + '/all-ratings'}>
                          <strong><span className="highlighted-text">Top Ratings</span></strong>
                        </a>
                      </div>
                    </div>
                    <div
                      onClick={() => this.toggleDropdown('showDropdownAbout')}
                      className={`top-ratings ${showDropdownAbout ? 'rotate' : ''}`}
                      style={{
                        backgroundColor: showDropdownAbout ? '#edeff2' : '',
                      }}
                    >
                      <div className="header-nav__drop-opener">
                        <a className="mobile-nav__item">About Techreviewer</a>
                        <span className="mobile-nav__drop-n-opener-icon" style={{ marginRight: 15 }}>
                          <Arrow />
                        </span>
                      </div>
                    </div>
                    {showDropdownAbout && (
                      <ul className="mobile-nav__drop-n-list">
                        <li className="mobile-nav__drop-n-item">
                          <a className="mobile-nav__drop-n-link" href={process.env.REACT_APP_TECHREVIEWER_URL + '/blog'}>
                            Blog
                          </a>
                        </li>
                        <li className="mobile-nav__drop-n-item">
                          <a className="mobile-nav__drop-n-link" href={process.env.REACT_APP_TECHREVIEWER_URL + '/contact-us'}>
                            Contacts
                          </a>
                        </li>
                      </ul>
                    )}
                    <div
                      onClick={() => this.toggleDropdown('showDropdownService')}
                      className={`top-ratings ${showDropdownService ? 'rotate' : ''}`}
                      style={{
                        backgroundColor: showDropdownService ? '#edeff2' : '',
                      }}
                    >
                      <div className="header-nav__drop-opener">                  
                        <a className="mobile-nav__item">Service companies</a>
                        <span className="mobile-nav__drop-n-opener-icon" style={{ marginRight: 15 }}>
                          <Arrow />
                        </span>
                      </div>
                    </div>
                    {showDropdownService && (
                      <ul className="mobile-nav__drop-n-list">
                        <li className="mobile-nav__drop-n-item">
                          <a className="mobile-nav__drop-n-link" href={process.env.REACT_APP_TECHREVIEWER_URL + '/why-get-listed'}>
                            Get listed
                          </a>
                        </li>
                        <li className="mobile-nav__drop-n-item">
                          <a className="mobile-nav__drop-n-link" href={process.env.REACT_APP_TECHREVIEWER_URL + '/become-featured'}>
                            Get featured
                          </a>
                        </li>
                        <li className="mobile-nav__drop-n-item">
                          <a className="mobile-nav__drop-n-link" href={process.env.REACT_APP_TECHREVIEWER_URL + '/widgets'}>
                            Widgets page
                          </a>
                        </li>
                        <li className="mobile-nav__drop-n-item">
                          <a className="mobile-nav__drop-n-link" href={process.env.REACT_APP_TECHREVIEWER_URL + '/write-for-us'}>
                            Write for us
                          </a>
                        </li>
                        <li className="mobile-nav__drop-n-item">
                          <a className="mobile-nav__drop-n-link" href={process.env.REACT_APP_TECHREVIEWER_URL + '/methodologies'}>
                            Methodologies we use
                          </a>
                        </li>
                      </ul>
                    )}
                    <div
                      onClick={() => this.toggleDropdown('showDropdownProfile')}
                      className={`top-ratings ${showDropdownProfile ? 'rotate' : ''}`}
                      style={{
                        backgroundColor: showDropdownProfile ? '#edeff2' : '',
                      }}
                    >
                      <div className="header-nav__drop-opener">                  
                        <a className="mobile-nav__item">Profile</a>
                        <span className="mobile-nav__drop-n-opener-icon" style={{ marginRight: 15 }}>
                          <Arrow />
                        </span>
                      </div>
                    </div>
                    {showDropdownProfile && (
                      <ul className="mobile-nav__drop-n-list">
                        <li className="mobile-nav__drop-n-item">
                          <a className="mobile-nav__drop-n-link" href={process.env.REACT_APP_TECHREVIEWER_URL + '/owners/profile'}>
                            Profile info
                          </a>
                        </li>
                        <li className="mobile-nav__drop-n-item">
                          <a className="mobile-nav__drop-n-link" href={process.env.REACT_APP_TECHREVIEWER_URL + '/owners/company'}>
                            Edit company page
                          </a>
                        </li>
                        <li className="mobile-nav__drop-n-item">
                          <a className="mobile-nav__drop-n-link" href='/analytics'>
                            Analytics benchmarks
                          </a>
                        </li>
                      </ul>
                    )}
                  </div>
                  <a className="mobile-nav__item mobile-nav__item--secondary" href={process.env.REACT_APP_TECHREVIEWER_URL + '/terms-of-use'} style={{ pointerEvents: 'auto !important' }}>
                    Terms of use
                  </a>
                  <a className="mobile-nav__item mobile-nav__item--secondary" href={process.env.REACT_APP_TECHREVIEWER_URL + '/privacy-policy'}>
                    Privacy policy
                  </a>
                  <div className="mobile-nav__socials">
                    <div className="socials-list">
                      <div className="footer-socials">
                        <a href="mailto:info@techreviewer.co" target="_blank" rel="noopener noreferrer">
                        </a>
                        <a href="https://www.linkedin.com/company/techreviewer/" target="_blank" rel="noopener noreferrer">
                        </a>
                        <a href="https://twitter.com/techreviewer_co" target="_blank" rel="noopener noreferrer">
                        </a>
                        <a href="https://www.facebook.com/techreviewer.co" target="_blank" rel="noopener noreferrer">
                        </a>
                      </div>
                      <div className="footer-socials socials">
                        <a className="social" href="mailto:info@techreviewer.co" target="_blank" rel="noopener noreferrer">
                          <svg className="header-socials__icon" width="20" height="20">
                            <use xlinkHref="#email" />
                          </svg>
                        </a>
                        <a className="social" href="https://www.linkedin.com/company/techreviewer/" target="_blank" rel="noopener noreferrer">
                          <svg className="header-socials__icon" width="32" height="32">
                            <use xlinkHref="#linkedin" />
                          </svg>
                        </a>
                        <a className="social" href="https://twitter.com/techreviewer_co" target="_blank" rel="noopener noreferrer">
                          <svg className="header-socials__icon" width="32" height="32">
                            <use xlinkHref="#twitter" />
                          </svg>
                        </a>
                        <a className="social" href="https://www.facebook.com/techreviewer.co" target="_blank" rel="noopener noreferrer">
                          <svg className="header-socials__icon" width="32" height="32">
                            <use xlinkHref="#facebook" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="mobile-nav__footer">
                    {currentPath === '/login' ? (
                      <a aria-current="page" className="profile-button" href={process.env.REACT_APP_TECHREVIEWER_URL + '/owners/profile'}>
                        Profile
                      </a>
                    ) : (
                      <a aria-current="page" className="profile-button" href="/logout">
                        Sign Out
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        <SvgIcons />
      </header>
    );
  }
}

export default PageHeader;
