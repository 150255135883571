import React, { useEffect } from 'react';

function SearchBox() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://cse.google.com/cse.js?cx=24abddb5f16ad483c';
        script.async = false;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return <div className="gcse-searchbox-only" data-enableAutoComplete="true" data-resultsUrl="https://techreviewer.co/search" data-autoCompleteMaxPromotions="5"></div>;
}

export default SearchBox;