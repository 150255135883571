import React, {useCallback, useState} from 'react';
import leftArrowSvg from './leftArrow.svg';

const LeftArrow = () => {
  return (
    <img 
      src={leftArrowSvg}
      style={{
        width: '12px',
        height: '12px',
        marginLeft: '8px',
        marginRight: '8px',
        alignSelf: 'center',
        position: 'absolute',
      }}
    />
  );
}

const DropdownMenu = ({ linkName, linkHref, categories }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleCloseDropdown = useCallback(() => { setIsOpen(false)}, []);

    const handleOpenDropdown = useCallback(() => { setIsOpen(true) }, []);

  return <details className="header-subnav__drop" onMouseLeave={handleCloseDropdown} open={isOpen}>
    <summary className="header-subnav__drop-button" onMouseEnter={handleOpenDropdown}>
      <LeftArrow />
      <span className="header-subnav__drop-opener">
        <a className="header-subnav__link" href={linkHref}>
          {linkName}
        </a>
      </span>
    </summary>
    <div className="header-subnav__drop-content">
      <div className="header-subnav__drop-container">
        {categories.map((category) => (
          <div className="header-subnav__drop-column" key={category.title}>
            <div className="header-subnav__drop-title">{category.title}</div>
              <ul className="header-subnav__drop-list">
                  {category.list.map((categoryItem) => (
                    <li className="header-subnav__drop-item" key={categoryItem.text}>
                        <a className="header-subnav__drop-link" href={categoryItem.link}>
                          {categoryItem.text}
                        </a>
                    </li>
                  ))}
              </ul>
          </div>
        ))}
      </div>
    </div>
</details>;
}

export default DropdownMenu;
