import React from 'react';

const SvgIcons = () => (
  <svg style={{ display: 'none' }}>
    <symbol id="twitter" viewBox="0 0 32 32">
      <path d="M25.8509 10.3042C25.1816 10.6115 24.4689 10.8139 23.7379 10.9042C24.5131 10.4262 25.0887 9.68326 25.3579 8.81318C24.6395 9.25385 23.848 9.56238 23.0209 9.72418C22.6805 9.34918 22.2654 9.04957 21.8023 8.84462C21.3392 8.63967 20.8383 8.53393 20.3319 8.53418C19.7691 8.5402 19.215 8.67364 18.7111 8.92448C18.2073 9.17533 17.7668 9.53705 17.4228 9.98249C17.0788 10.4279 16.8402 10.9455 16.7248 11.4964C16.6095 12.0473 16.6205 12.6171 16.7569 13.1632C15.2861 13.0828 13.8493 12.6897 12.5425 12.0099C11.2357 11.3301 10.089 10.3794 9.17888 9.22118C8.70405 10.0518 8.55792 11.0303 8.76934 11.9634C8.98077 12.8965 9.53439 13.7164 10.3209 14.2612C9.73525 14.2397 9.16371 14.0757 8.65588 13.7832V13.8342C8.64918 14.6954 8.93842 15.5328 9.47523 16.2064C10.012 16.8799 10.7638 17.3486 11.6049 17.5342C11.0643 17.6825 10.4967 17.703 9.94688 17.5942C10.1734 18.3351 10.6267 18.9863 11.243 19.4558C11.8593 19.9253 12.6074 20.1895 13.3819 20.2112C12.6252 20.825 11.7527 21.2802 10.8164 21.5496C9.88005 21.819 8.89905 21.8971 7.93188 21.7792C9.60473 22.8823 11.5651 23.469 13.5689 23.4662C20.3319 23.4672 24.0219 17.7182 24.0219 12.7342V12.2522C24.7421 11.7163 25.3614 11.0567 25.8509 10.3042V10.3042Z" fill="currentColor"/>
    </symbol>

    <symbol id="email" viewBox="0 0 32 32">
      <path d="M8.716 9H22.446C22.9007 9.00132 23.3364 9.18254 23.6579 9.50406C23.9795 9.82559 24.1607 10.2613 24.162 10.716V21.016C24.1607 21.4707 23.9795 21.9064 23.6579 22.2279C23.3364 22.5495 22.9007 22.7307 22.446 22.732H8.716C8.26078 22.7307 7.82462 22.549 7.50301 22.2269C7.18139 21.9047 7.00053 21.4682 7 21.013V10.716C7.00132 10.2613 7.18254 9.82559 7.50406 9.50406C7.82559 9.18254 8.26129 9.00132 8.716 9V9Z" fill="currentColor"/>
      <path d="M24.162 10.7158L15.581 16.7228L7 10.7158" stroke="white"/>
      <path d="M24.3 10L15.6 16.2L6.8 10" fill="currentColor"/>
    </symbol>

    <symbol id="linkedin" viewBox="0 0 32 32">
      <path d="M7.80167 23.934H11.5731V12.6232H7.80167V23.934ZM9.66709 11.0819H9.68791C9.95716 11.1064 10.2286 11.0748 10.485 10.9892C10.7414 10.9035 10.9773 10.7656 11.1776 10.5841C11.378 10.4026 11.5386 10.1816 11.6492 9.93496C11.7598 9.68831 11.818 9.42141 11.8202 9.15111C11.8223 8.88082 11.7684 8.61302 11.6618 8.36462C11.5552 8.11623 11.3982 7.89265 11.2007 7.70801C11.0032 7.52337 10.7696 7.38168 10.5146 7.2919C10.2596 7.20212 9.98875 7.16619 9.71914 7.1864C9.45121 7.16256 9.18124 7.19429 8.92614 7.2796C8.67104 7.36491 8.4363 7.50196 8.23664 7.68217C8.03697 7.86239 7.87668 8.08188 7.76579 8.32689C7.6549 8.57191 7.59581 8.83718 7.59222 9.10609C7.58862 9.375 7.64061 9.64176 7.74491 9.88965C7.84921 10.1375 8.00359 10.3612 8.19837 10.5467C8.39314 10.7322 8.62413 10.8755 8.87686 10.9676C9.12959 11.0596 9.39862 11.0986 9.66709 11.0819ZM21.4061 23.933H25.188L25.1974 23.9382V17.471C25.1974 14.0033 23.333 12.3787 20.843 12.3787C20.1599 12.3516 19.4824 12.5118 18.8839 12.842C18.2854 13.1722 17.7886 13.6598 17.4473 14.252V12.6482H13.6665C13.7186 13.7098 13.6665 23.9372 13.6665 23.9372H17.438V17.6365C17.4177 17.3264 17.4566 17.0152 17.5525 16.7196C17.698 16.3191 17.9626 15.9727 18.3108 15.727C18.6589 15.4812 19.0739 15.3479 19.5001 15.3448C20.8648 15.3448 21.4061 16.3866 21.4061 17.8967V23.933Z" fill="currentColor"/>
    </symbol>

    <symbol id="facebook" viewBox="0 0 32 32">
      <path d="M20.8051 6.70703V10.152H19.3921C19.1086 10.1078 18.8187 10.1299 18.5452 10.2165C18.2717 10.303 18.0219 10.4518 17.8155 10.651C17.6091 10.8503 17.4516 11.0947 17.3554 11.365C17.2592 11.6353 17.2269 11.9242 17.2611 12.209V13.599H20.8051L20.0991 17.031H17.2621V25.296H13.7301V17.031H10.8921V13.596H13.7291V11.527C13.7291 8.69003 15.3671 6.70703 17.9671 6.70703H20.8051Z" fill="currentColor"/>
    </symbol>
  </svg>
);

export default SvgIcons;
