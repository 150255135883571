import React, { useState, useEffect } from 'react';
import './navigationBar.scss';

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const NavigationBar = () => {
  const [selectedSection, setSelectedSection] = useState('organic-traffic');
  const sectionIds = [
    'organic-traffic',
    'organic-keywords',
    'authority-score',
    'referring-domains',
    'backlinks',
    'average-rate',
    'employees',
    'min-project-size',
  ];

  const [scrollDirection, setScrollDirection] = useState(null);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [isInteracting, setIsInteracting] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      if (!isInteracting) {
        if (currentScrollPos > prevScrollPos && scrollDirection !== "down") {
          setScrollDirection("down");
        }
        setPrevScrollPos(currentScrollPos);

        const middleOfScreen = window.scrollY + window.innerHeight / 2 + (scrollDirection === "down" ? 4 : 68 );

        for (const sectionId of sectionIds) {
          const section = document.getElementById(sectionId);
          if (section) {
            const sectionTop = section.offsetTop + (scrollDirection === "down" ? -68 : 0);
            const sectionBottom = sectionTop + section.offsetHeight;

            if (middleOfScreen >= sectionTop && middleOfScreen < sectionBottom && selectedSection !== sectionId) {
              setSelectedSection(sectionId);
              break;
            }
          }
        }
      }
    };

    const debounceScroll = debounce(handleScroll);

    window.addEventListener('scroll', debounceScroll);

    return () => {
      window.removeEventListener('scroll', debounceScroll);
    };
  }, [selectedSection, isInteracting]);

  const handleScrollToSection = (sectionId) => {
    if (!isInteracting) {
      setIsInteracting(true);
      setSelectedSection(sectionId);
      const section = document.getElementById(sectionId);
      if (section) {
        const sectionTop = section.offsetTop - 86;
        window.scrollTo({ top: sectionTop, behavior: 'smooth' });

        setTimeout(() => {
          setIsInteracting(false);
        }, 700);
      }
    }
  };

  return (
    <nav className="navigation-bar">
      <ul className="navigation-list">
        {sectionIds.map((sectionId) => (
          <li
            key={sectionId}
            className={selectedSection === sectionId ? 'active' : ''}
            onClick={() => handleScrollToSection(sectionId)}
          >
            {sectionId.replace(/-/g, ' ')}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavigationBar;
