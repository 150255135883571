import React, { useEffect } from "react";
import withRouter from "components/Common/withRouter";

// Layout Related Components
import Header from "./Header";
import Footer from "./Footer";
const Layout = props => {
  return (
    <React.Fragment>
      <div id="layout-wrapper">
        <Header/>
          <div className="main-block">{props.children}</div>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default withRouter(Layout);