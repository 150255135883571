import React from "react"
import ReactApexChart from "react-apexcharts"

const AnalyticChart = ({ periodData }) => {
    const options = {
        chart: {
            type: 'bar',
            height: 380,
            stacked: true,
            toolbar: {
                show: false
            },
        },
        grid: {
            show: true,
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true,
                }
            },
        },
        plotOptions: {
            bar: {
                distributed: false,
                horizontal: false,
                columnWidth: "50%"
            }
        },
        dataLabels: {
            enabled: !1
        },
        xaxis: {
            show: true,
            categories: [
                "Very Low",
                "Low",
                "Below Average",
                "Average",
                "Above Average",
                "Good",
                "Great",
                "Excellent"
            ],
            labels: {
                show: true
            }
        },
        states: {
            hover: {
                filter: {
                    type: 'none'
                }
            }
        },
        colors: ["#D9D9D9", "#DB4746"],
        legend: {
            position: "top"
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    return value
                }
            },
            z: {
                formatter: function (value) {
                    return null
                }
            }
        }
    }

    return (
        <React.Fragment>
            <ReactApexChart
                options={options}
                series={periodData}
                type="bar"
                height="359"
                className="apex-charts"
            />
        </React.Fragment>
    );
}

export default AnalyticChart;
