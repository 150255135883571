import {
    GET_USER_SETTINGS_SUCCESS,
    GET_USER_SETTINGS_FAIL,
    POST_CREATE_MY_SETTINGS,
    POST_CREATE_MY_SETTINGS_SUCCESS,
    POST_CREATE_MY_SETTINGS_FAIL
} from "./actionTypes"

const INIT_STATE = {
    error: "",
    loading: false,
    userSettings: [],
}

const mySettings = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_USER_SETTINGS_SUCCESS:
            return {
                ...state,
                userSettings: action.payload,
            };

        case GET_USER_SETTINGS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        case POST_CREATE_MY_SETTINGS:
            return {
                ...state,
                loading: true,
            };

        case POST_CREATE_MY_SETTINGS_SUCCESS:
            return {
                ...state,
                mySetting: action.payload,
            };

        case POST_CREATE_MY_SETTINGS_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
}

export default mySettings