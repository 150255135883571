import axios from "axios";

//pass new generated access token here
const token = localStorage.getItem("authUser")

//apply base url for axios
const API_URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
  baseURL: API_URL,
});

axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  (response) => response,
    (error) => {
      if (error.response.status === 401) {
          window.location.replace('/login');
      }
      return Promise.reject(error);
    }
);

export async function get(url, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = localStorage.getItem("authUser");
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function getForAuth(url, config = {}) {
    return await axiosApi
        .get(url, { ...config })
        .then((response) => response);
}

export async function post(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = localStorage.getItem("authUser");
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
