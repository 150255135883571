import { takeEvery, put, call  } from "redux-saga/effects";

import { GET_USER_SETTINGS, POST_CREATE_MY_SETTINGS } from "./actionTypes";

import {
    getUserSettingsSuccess,
    getUserSettingsFail,
    postCreateMySettingsSuccess,
    postCreateMySettingsFail
} from "./actions"

import { getUserSettings, postCreateMySettings as postCreateMySettingsApi } from "../../helpers/fakebackend_helper";

function* fetchUserSettings() {
    try {
        const response = yield call(getUserSettings)
        yield put(getUserSettingsSuccess(response))
    } catch (error) {
        yield put(getUserSettingsFail(error))
    }
}

function* postCreateMySettings({ payload: { mySetting } }) {
    try {
        const response = yield call(postCreateMySettingsApi, {
            my_setting: {
                main_service_line: mySetting.main_service_line,
                employees_number: mySetting.employees_number,
                avg_hourly_rate: mySetting.avg_hourly_rate
            }
        });
        yield put(postCreateMySettingsSuccess(response));
    } catch (error) {
        yield put(postCreateMySettingsFail(error.response.data.message));
    }
}

function* mySettingsSaga() {
    yield takeEvery(GET_USER_SETTINGS, fetchUserSettings);
    yield takeEvery(POST_CREATE_MY_SETTINGS, postCreateMySettings);
}

export default mySettingsSaga;