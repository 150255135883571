import React, { useEffect, useState } from "react";
import {CardBody, Card} from "reactstrap";
import AnalyticChart from "../../../pages/Charts/AnalyticChart";
import Recommendations from "../recommendations/recommendations";
import './styles.scss'

const OrganicKeywords = ({customPeriod, userGroup, companyName}) => {
    const [periodData, setPeriodData] = useState([{
        name: "Other companies",
        data: [{x: "very_low", y: 0, z: false},
            {x: "low", y: 0, z: false},
            {x: "below_average", y: 0, z: false},
            {x: "average", y: 0, z: false },
            {x: "above_average", y: 0, z: false},
            {x: "good_division", y: 0, z: false},
            {x: "great", y: 0, z: false},
            {x: "excellent", y: 0, z: false}]
    }, {
        name: "Includes your company",
        data: []
    }]);

    useEffect(() => {
        if(customPeriod){
            setPeriodData(customPeriod);
        }
        else{
            setPeriodData([{
                name: "Other companies",
                data: [{x: "very_low", y: 0, z: false},
                    {x: "low", y: 0, z: false},
                    {x: "below_average", y: 0, z: false},
                    {x: "average", y: 0, z: false },
                    {x: "above_average", y: 0, z: false},
                    {x: "good_division", y: 0, z: false},
                    {x: "great", y: 0, z: false},
                    {x: "excellent", y: 0, z: false}]
            }, {
                name: "Includes your company",
                data: []
            }])
        }
    }, [customPeriod]);

    return (
        <Card>
            <CardBody>
                <div className="chart-title">Organic keywords</div>
                <div className="d-sm-flex flex-wrap">
                    <h6 className="chart-text">How {companyName} compares to other companies based on organic keywords</h6>
                    <div className="ms-auto">
                        <ul className="nav nav-pills">
                        </ul>
                    </div>
                </div>
                <AnalyticChart periodData={periodData} />
                <Recommendations userGroup={userGroup} chartName={'organicKeywords'} />
            </CardBody>
        </Card>
    );
};

export default OrganicKeywords;
