export const headerButtonsConfig = [
  {
    name: 'Software Development',
    link: 'https://techreviewer.co/top-software-development-companies',
    categories: [
      {
        title: 'Technologies',
        list: [
          {
            text: '.NET Developers',
            link: 'https://techreviewer.co/top-net-developers',
          },
          {
            text: 'Python and Django Developers',
            link: 'https://techreviewer.co/top-python-and-django-developers',
          },
          {
            text: 'Java Developers',
            link: 'https://techreviewer.co/java-developers',
          },
          {
            text: 'Unity Developers',
            link: 'https://techreviewer.co/unity-developers',
          },
          {
            text: 'Laravel Developers',
            link: 'https://techreviewer.co/laravel',
          },
          {
            text: 'JavaScript Developers',
            link: 'https://techreviewer.co/javascript',
          },
        ],
      },
      {
        title: 'Solution Type',
        list: [
          {
            text: 'Enterprise',
            link: 'https://techreviewer.co/top-software-development-companies/enterprise-software',
          },
          {
            text: 'Startups',
            link: 'https://techreviewer.co/top-software-development-companies-for-startups',
          },
        ],
      },
      {
        title: 'Location',
        list: [
          {
            text: 'Unites States',
            link: 'https://techreviewer.co/top-software-development-companies-in-usa',
          },
          {
            text: 'Australia',
            link: 'https://techreviewer.co/top-software-development-companies/australia',
          },
          {
            text: 'Canada',
            link: 'https://techreviewer.co/top-software-development-companies/canada',
          },
          {
            text: 'Germany',
            link: 'https://techreviewer.co/top-software-development-companies/germany',
          },
          {
            text: 'Netherlands',
            link: 'https://techreviewer.co/top-software-development-companies/netherlands',
          },
          {
            text: 'Poland',
            link: 'https://techreviewer.co/top-software-development-companies/poland',
          },
          {
            text: 'UAE',
            link: 'https://techreviewer.co/top-software-development-companies/uae',
          },
          {
            text: 'Ukraine',
            link: 'https://techreviewer.co/ua/top-software-development-companies',
          },
          {
            text: 'United Kingdom',
            link: 'https://techreviewer.co/top-software-development-companies/united-kingdom',
          },
        ],
      },
    ],
  },
  {
    name: 'Web Development',
    link: 'https://techreviewer.co/top-web-development-companies',
    categories: [
      {
        title: 'Technologies',
        list: [
          {
            text: 'AngularJS Developers',
            link: 'https://techreviewer.co/angularjs-developers',
          },
          {
            text: 'Node.js Developers',
            link: 'https://techreviewer.co/top-node-js-development-companies',
          },
          {
            text: 'PHP Developers',
            link: 'https://techreviewer.co/top-php-developers',
          },
           {
            text: 'React Developers',
            link: 'https://techreviewer.co/reactjs',
          },
          {
            text: 'Ruby on Rails Companies',
            link: 'https://techreviewer.co/top-ruby-on-rails-companies',
          },
          {
            text: 'Vue.js Developers',
            link: 'https://techreviewer.co/vue-js',
          },
        ],
      },
      {
        title: 'CMS',
        list: [
          {
            text: 'Drupal Developers',
            link: 'https://techreviewer.co/drupal-developers',
          },
          {
            text: 'Webflow Developers',
            link: 'https://techreviewer.co/webflow',
          },
          {
            text: 'Wix Developers',
            link: 'https://techreviewer.co/wix',
          },
          {
            text: 'WordPress Developers',
            link: 'https://techreviewer.co/top-wordpress-developers',
          },
        ],
      },
      {
        title: 'Location',
        list: [
          {
            text: 'Unites States',
            link: ' https://techreviewer.co/us/top-web-development-companies',
          },
          {
            text: 'Australia',
            link: 'https://techreviewer.co/top-web-development-companies/australia',
          },
          {
            text: 'Canada',
            link: 'https://techreviewer.co/top-web-development-companies/canada',
          },
          {
            text: 'Germany',
            link: 'https://techreviewer.co/top-web-development-companies/germany',
          },
          {
            text: 'Netherlands',
            link: 'https://techreviewer.co/top-web-development-companies/netherlands',
          },
          {
            text: 'Poland',
            link: 'https://techreviewer.co/top-web-development-companies/poland',
          },
          {
            text: 'Singapore',
            link: 'https://techreviewer.co/top-web-development-companies/singapore',
          },
          {
            text: 'UAE',
            link: 'https://techreviewer.co/top-web-development-companies/uae',
          },
          {
            text: 'Ukraine',
            link: 'https://techreviewer.co/top-web-development-companies/ukraine',
          },
          {
            text: 'United Kingdom',
            link: 'https://techreviewer.co/top-web-development-companies/united-kingdom',
          },
          {
            text: 'Vietnam',
            link: 'https://techreviewer.co/top-web-development-companies/vietnam',
          },
        ],
      },
    ],
  },
  {
    name: 'eCommerce Development',
    link: 'https://techreviewer.co/top-ecommerce-development-companies',
    categories: [
      {
        title: 'CMS',
        list: [
          {
            text: 'Shopify Developers',
            link: 'https://techreviewer.co/top-shopify-developers',
          },
          {
            text: 'Magento Developers',
            link: 'https://techreviewer.co/top-magento-development-companies',
          },
          {
            text: 'BigCommerce Developers',
            link: 'https://techreviewer.co/bigcommerce-developers',
          },
          {
            text: 'WooCommerce Developers',
            link: 'https://techreviewer.co/woocommerce-developers',
          },
        ],
      },
      {
        title: 'Location',
        list: [
          {
            text: 'Unites States',
            link: 'https://techreviewer.co/top-ecommerce-development-companies/united-states',
          },
          {
            text: 'Australia',
            link: 'https://techreviewer.co/top-ecommerce-development-companies/australia',
          },
          {
            text: 'Canada',
            link: 'https://techreviewer.co/top-ecommerce-development-companies/canada',
          },
          {
            text: 'UAE',
            link: 'https://techreviewer.co/top-ecommerce-development-companies/uae',
          },
          {
            text: 'United Kingdom',
            link: 'https://techreviewer.co/top-ecommerce-development-companies/united-kingdom',
          },
        ],
      },
    ],
  },
  {
    name: 'IT Services',
    link: 'https://techreviewer.co/top-it-services-companies',
    categories: [
      {
        title: 'Service Line',
        list: [
          {
            text: 'Managed IT Service Providers',
            link: 'https://techreviewer.co/msp',
          },
          {
            text: 'Cloud Consulting Companies',
            link: 'https://techreviewer.co/top-cloud-consulting-companies',
          },
          {
            text: 'Cybersecurity Companies',
            link: 'https://techreviewer.co/top-cybersecurity-companies',
          },
          {
            text: 'DevOps Consulting Companies',
            link: 'https://techreviewer.co/devops-companies',
          },
          {
            text: 'Chatbot Development Companies',
            link: 'https://techreviewer.co/chatbot-companies',
          },
        ],
      },
      {
        title: 'Location',
        list: [
          {
            text: 'Unites States',
            link: 'https://techreviewer.co/top-it-services-companies/usa',
          },
          {
            text: 'United Kingdom',
            link: 'https://techreviewer.co/top-it-services-companies/uk',
          },
          {
            text: 'London',
            link: 'https://techreviewer.co/top-it-services-companies/uk/london',
          },
          {
            text: 'Dubai',
            link: 'https://techreviewer.co/top-it-companies-in-dubai',
          },
        ],
      },
    ],
  },
  {
    name: 'Mobile Development',
    link: 'https://techreviewer.co/top-mobile-development-companies',
    categories: [
      {
        title: 'Platform',
        list: [
          {
            text: 'iOS Developers',
            link: 'https://techreviewer.co/top-ios-app-development-companies',
          },
          {
            text: 'Android Developers',
            link: 'https://techreviewer.co/top-android-app-development-companies',
          },
          {
            text: 'Cross Platform Developers',
            link: 'https://techreviewer.co/top-cross-platform-app-developers',
          },
          {
            text: 'Wearable App Developers',
            link: 'https://techreviewer.co/app-developers/wearable',
          },
        ],
      },
      {
        title: 'Frameworks',
        list: [
          {
            text: 'React Native Developers',
            link: 'https://techreviewer.co/react-native',
          },
          {
            text: 'Flutter Developers',
            link: 'https://techreviewer.co/app-developers/flutter',
          },
          {
            text: 'Swift Developers',
            link: 'https://techreviewer.co/swift',
          },
          {
            text: 'Xamarin Developers',
            link: 'https://techreviewer.co/app-developers/xamarin',
          },
        ],
      },
      {
        title: 'Location',
        list: [
          {
            text: 'Unites States',
            link: 'https://techreviewer.co/us/app-developers',
          },
          {
            text: 'Australia',
            link: 'https://techreviewer.co/app-developers/australia',
          },
          {
            text: 'Canada',
            link: 'https://techreviewer.co/app-developers/canada',
          },
          {
            text: 'Germany',
            link: 'https://techreviewer.co/app-developers/germany',
          },
          {
            text: 'Netherlands',
            link: 'https://techreviewer.co/app-developers/netherlands',
          },
          {
            text: 'Poland',
            link: 'https://techreviewer.co/app-developers/poland',
          },
          {
            text: 'Singapore',
            link: 'https://techreviewer.co/app-developers/singapore',
          },
          {
            text: 'UAE',
            link: 'https://techreviewer.co/app-developers/uae',
          },
          {
            text: 'Ukraine',
            link: 'https://techreviewer.co/app-developers/ukraine',
          },
          {
            text: 'United Kingdom',
            link: 'https://techreviewer.co/app-developers/united-kingdom',
          },
          {
            text: 'Vietnam',
            link: 'https://techreviewer.co/app-developers/vietnam',
          },
        ],
      },
    ],
  },
  {
    name: 'Design',
    link: 'https://techreviewer.co/top-web-design-companies',
    categories: [
      {
        title: 'Service Line',
        list: [
          {
            text: 'Graphic Design Companies',
            link: 'https://techreviewer.co/top-web-design-companies/graphic-design',
          },
          {
            text: 'Logo Design Companies',
            link: 'https://techreviewer.co/top-web-design-companies/logo-design',
          },
          {
            text: '3D Modeling Companies',
            link: 'https://techreviewer.co/3d-modeling',
          },
          {
            text: 'Web Design for Small Business',
            link: 'https://techreviewer.co/top-web-design-companies/small-business',
          },
        ],
      },
      {
        title: 'Location',
        list: [
          {
            text: 'United States',
            link: 'https://techreviewer.co/top-web-design-companies/united-states',
          },
          {
            text: 'Canada',
            link: 'https://techreviewer.co/top-web-design-companies/canada',
          },
          {
            text: 'Chicago',
            link: 'https://techreviewer.co/top-web-design-companies/chicago',
          },
          {
            text: 'Denver',
            link: 'https://techreviewer.co/top-web-design-companies/denver',
          },
          {
            text: 'Minneapolis',
            link: 'https://techreviewer.co/top-web-design-companies/minneapolis',
          },
          {
            text: 'Sacramento',
            link: 'https://techreviewer.co/top-web-design-companies/sacramento',
          },
          {
            text: 'San Diego',
            link: 'https://techreviewer.co/top-web-design-companies/san-diego',
          },
          {
            text: 'Toronto',
            link: 'https://techreviewer.co/top-web-design-companies-in-toronto',
          },
        ],
      },
    ],
  },
  {
    name: 'Artificial Intelligence',
    link: 'https://techreviewer.co/top-artificial-intelligence-companies',
    categories: [
      {
        title: 'Service Line',
        list: [
          {
            text: 'Data Analytics Companies',
            link: 'https://techreviewer.co/top-big-data-analytics-companies',
          },
          {
            text: 'Machine Learning Companies',
            link: 'https://techreviewer.co/top-machine-learning-companies',
          },
          {
            text: 'Robotics Companies',
            link: 'https://techreviewer.co/top-robotics-companies',
          },
        ],
      },
    ],
  },
  {
    name: 'Blockchain',
    link: 'https://techreviewer.co/top-blockchain-development-companies',
    categories: [
      {
        title: 'Location',
        list: [
          {
            text: 'Unites States',
            link: 'https://techreviewer.co/top-blockchain-development-companies/united-states',
          },
          {
            text: 'Australia',
            link: 'https://techreviewer.co/top-blockchain-development-companies/australia',
          },
          {
            text: 'Ukraine',
            link: 'https://techreviewer.co/top-blockchain-development-companies/ukraine',
          },
        ],
      },
    ],
  },
  {
    name: 'IoT Development',
    link: 'https://techreviewer.co/top-iot-development-companies',
    categories: [
      {
        title: 'Location',
        list: [
          {
            text: 'Unites States',
            link: 'https://techreviewer.co/top-iot-development-companies/united-states',
          },
          {
            text: 'United Kingdom',
            link: 'https://techreviewer.co/top-iot-development-companies/united-kingdom',
          },
          {
            text: 'California',
            link: 'https://techreviewer.co/top-iot-development-companies/united-states/california',
          },
          {
            text: 'Illinois',
            link: 'https://techreviewer.co/top-iot-development-companies/united-states/illinois',
          },
          {
            text: 'London',
            link: 'https://techreviewer.co/top-iot-development-companies/united-kingdom/london',
          },
          {
            text: 'New York',
            link: 'https://techreviewer.co/top-iot-development-companies/united-states/new-york',
          },
        ],
      },
    ],
  },
  {
    name: 'Game Development',
    link: 'https://techreviewer.co/top-game-development-companies',
    categories: [
      {
        title: 'Location',
        list: [
          {
            text: 'Unites States',
            link: 'https://techreviewer.co/top-game-development-companies/united-states',
          },
          {
            text: 'Canada',
            link: 'https://techreviewer.co/top-game-development-companies/canada',
          },
          {
            text: 'Chicago',
            link: 'https://techreviewer.co/top-game-development-companies/chicago',
          },
          {
            text: 'California',
            link: 'https://techreviewer.co/top-game-development-companies/california',
          },
          {
            text: 'Florida',
            link: 'https://techreviewer.co/top-game-development-companies/florida',
          },
          {
            text: 'Los Angeles',
            link: 'https://techreviewer.co/top-game-development-companies/los-angeles',
          },
          {
            text: 'New York',
            link: 'https://techreviewer.co/top-game-development-companies/new-york',
          },
          {
            text: 'North Carolina',
            link: 'https://techreviewer.co/top-game-development-companies/north-carolina',
          },
          {
            text: 'San Francisco',
            link: 'https://techreviewer.co/top-game-development-companies/san-francisco',
          },
          {
            text: 'Seattle',
            link: 'https://techreviewer.co/top-game-development-companies/seattle',
          },
          {
            text: 'Texas',
            link: 'https://techreviewer.co/top-game-development-companies/texas',
          },
        ],
      },
    ],
  },
  {
    name: 'QA and Software Testing',
    link: 'https://techreviewer.co/top-qa-and-testing-companies',
  },
  {
    name: 'VR & AR Development',
    link: 'https://techreviewer.co/top-vr-and-ar-companies',
  },
];
