export const employeesNumberOptions = [
    { value: 'less10', label: '<10' },
    { value: '10_49', label: '10-49' },
    { value: '50_99', label: '50-99' },
    { value: '100_249', label: '100-249' },
    { value: '250_999', label: '250-999' },
    { value: '1000plus', label: '1000+' },
]

export const avgHourlyRateOptions = [
    { value: 'less20', label: '<$20 /hr' },
    { value: '20_29', label: '$20 - $29 /hr' },
    { value: '30_49', label: '$30 - $49 /hr' },
    { value: '50_99', label: '$50 - $99 /hr' },
    { value: '100_149', label: '$100 - $149 /hr' },
    { value: '150_199', label: '$150 - $199 /hr' },
    { value: 'more200', label: '>$200 /hr' },
]

export const minimumProjectSizeOptions = [
    { value: '0', label: 'Clear..' },
    { value: 'less5000', label: '<$5,000' },
    { value: '5000plus', label: '$5,000+' },
    { value: '10000plus', label: '$10,000+' },
    { value: '25000plus', label: '$25,000+' },
    { value: '50000plus', label: '$50,000+' },
    { value: '75000plus', label: '$75,000+' },
    { value: '100000plus', label: '$100,000+' },
]

export const employeesRefactor = value => {
    switch(value) {
        case 'less10':
            return { value: 'less10', label: '<10' };
        case '10_49':
            return { value: '10_49', label: '10-49' };
        case '50_99':
            return { value: '50_99', label: '50-99' };
        case '100_249':
            return { value: '100_249', label: '100-249' };
        case '250_999':
            return { value: '250_999', label: '250-999' };
        case '1000plus':
            return { value: '1000plus', label: '1000+' };
        default:
            return { value: '', label: '' };
    }
};

export const rateRefactor = value => {
    switch(value) {
        case 'less20':
            return { value: 'less20', label: '<$20 /hr' };
        case '20_29':
            return { value: '20_29', label: '$20 - $29 /hr' };
        case '30_49':
            return { value: '30_49', label: '$30 - $49 /hr' };
        case '50_99':
            return { value: '50_99', label: '$50 - $99 /hr' };
        case '100_149':
            return { value: '100_149', label: '$100 - $149 /hr' };
        case '150_199':
            return { value: '150_199', label: '$150 - $199 /hr' };
        case 'more200':
            return { value: 'more200', label: '>$200 /hr' };
        default:
            return { value: '', label: '' };
    }
};