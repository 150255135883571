import React, { useEffect, useState } from "react";
import {CardBody, Card} from "reactstrap";
import CompanyAttributeChart from "../../../pages/Charts/CompanyAttributeChart";
import './styles.scss'

const AverageRate = ({customPeriod, companyName}) => {
    const [periodData, setPeriodData] = useState([{
        name: "Other companies",
        data: [{x: "<$20 / hr", y: 0, z: true},
            {x: "$20 - $29 / hr", y: 0, z: false},
            {x: "$30 - $49 / hr", y: 0, z: false},
            {x: "$50 - $99 / hr",y: 0, z: false},
            {x: "$100 - $149 / hr", y: 0, z: false},
            {x: "$150 - $199 / hr", y: 0, z: false},
            {x: ">$200 / hr", y: 0, z: false}]
    }, {
        name: "Includes your company",
        data: []
    }]);

    useEffect(() => {
        if(customPeriod){
            setPeriodData(customPeriod);
        }
        else{
            setPeriodData([{
                name: "Other companies",
                data: [{x: "<$20 / hr", y: 0, z: true},
                    {x: "$20 - $29 / hr", y: 0, z: false},
                    {x: "$30 - $49 / hr", y: 0, z: false},
                    {x: "$50 - $99 / hr",y: 0, z: false},
                    {x: "$100 - $149 / hr", y: 0, z: false},
                    {x: "$150 - $199 / hr", y: 0, z: false},
                    {x: ">$200 / hr", y: 0, z: false}]
            }, {
                name: "Includes your company",
                data: []
            }])
        }
    }, [customPeriod]);

    return (
        <Card>
            <CardBody>
                <div className="chart-title">Average rate</div>
                <div className="d-sm-flex flex-wrap">
                    <h6 className="chart-text">How {companyName}  compares to other companies based on average rate</h6>
                    <div className="ms-auto">
                        <ul className="nav nav-pills">
                        </ul>
                    </div>
                </div>
                <CompanyAttributeChart periodData={periodData} />
            </CardBody>
        </Card>
    );
};

export default AverageRate;