import PropTypes from "prop-types";
import React, {useEffect, useState} from 'react'

import Header from "../../components/VerticalLayout/Header";
import Footer from "../../components/VerticalLayout/Footer";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";

// Formik validation
import { useFormik } from "formik";

// actions
import { loginUser, socialLogin } from "../../store/actions";

import './styles.scss';

const Login = props => {

  //meta title
  document.title = "Login | Techreviewer.co – Analytics Benchmarks";

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this  flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    }
  });

  useEffect(() => {
    localStorage.removeItem("authUser");
  }, []);

  const { error } = useSelector(state => ({
    error: state.Login.error,
  }));

    const signIn = type => {
        dispatch(socialLogin(type, props.router.navigate));
    };

  //for facebook and google authentication
  const socialResponse = type => {
    signIn(type);
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <React.Fragment>
      <Header/>
      <div className="page-content" style={{paddingTop: "16px", paddingBottom: "50px"}}>
        <div className="account-pages my-5 pt-sm-5">
          <Container>
            <Row className="justify-content-center">
              <Col md={8} lg={6} xl={5} style={{width: "unset"}}>
                <Card className="overflow-hidden" style={{maxWidth: 600}}>
                  <CardBody className="pt-0 login-block">
                    <div className="p-2">
                      <Form
                        className="form-horizontal"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        <h5 className="title">Sign in</h5>
                        <div className="mb-3">
                          <Label className="form-label">Email *</Label>
                          <Input
                            name="email"
                            className="form-control sign-in-form"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                          />
                        </div>

                        <div>
                          <Label className="form-label">Password *</Label>
                          <Link to={`${process.env.REACT_APP_TECHREVIEWER_URL}/users/password/new`} className="text-muted forget-password-btn">
                            Forgot password?
                          </Link>
                          <Input
                            name="password"
                            className="sign-in-form"
                            value={validation.values.password || ""}
                            type={passwordVisible ? "text" : "password"}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            id="password"
                            style={{ paddingRight: "30px" }}
                          />
                          <span
                              className="toggle-password"
                              onClick={togglePasswordVisibility}>
                            {passwordVisible ? (
                                <FontAwesomeIcon icon={faEye} />
                            ) : (
                                <FontAwesomeIcon icon={faEyeSlash} />
                            )}
                          </span>
                        </div>

                        <div className="mt-2 d-grid sign-in-btn-block">
                          <button
                            className="btn btn-block sign-in-btn"
                            type="submit"
                          >
                            SIGN IN
                          </button>
                        </div>
                        <div className="join-link">
                          <div className="normal-text">You want to join Techreviewer?&nbsp;</div><a className="link-button" href={`${process.env.REACT_APP_TECHREVIEWER_URL}/users/sign_up`}><div className="link-button-text">sign up</div><div className="link-button-arrow"></div></a>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
