import React from "react"
import './styles.scss'
import logoSvg from './techReviewerLogo.svg';
import SvgIcons from './mobile_icons.js';

const Logo = () => {
  return (
    <div>
      <img 
        src={logoSvg}
        alt="Logo" 
        style={{
          width: '190px',
          marginTop: '20px',
          marginBottom: '40px'
        }}
      />
    </div>
  );
}

const footerNav = [
  {
    title: 'Top Ratings',
    column: '2',
    list: [
      {
        text: 'Top Software Development Companies',
        link: 'https://techreviewer.co/top-software-development-companies',
      },
      {
        text: 'Top Software Development Companies for Startups',
        link: 'https://techreviewer.co/top-software-development-companies-for-startups',
      },
      {
        text: 'Top Software Development Companies in USA',
        link: 'https://techreviewer.co/top-software-development-companies-in-usa',
      },
      {
        text: 'Top IT Services Companies',
        link: 'https://techreviewer.co/top-it-services-companies',
      },
      {
        text: 'Top Web Development Companies',
        link: 'https://techreviewer.co/top-web-development-companies',
      },
      {
        text: 'Top Mobile Development Companies',
        link: 'https://techreviewer.co/top-mobile-development-companies',
      },
      {
        text: 'Top iOS App Development Companies',
        link: 'https://techreviewer.co/top-ios-app-development-companies',
      },
      {
        text: 'Top Android App Development Companies',
        link: 'https://techreviewer.co/top-android-app-development-companies',
      },
      {
        text: 'Top IoT Development Companies',
        link: 'https://techreviewer.co/top-iot-development-companies',
      },
      {
        text: 'Top Artificial Intelligence Companies',
        link: 'https://techreviewer.co/top-artificial-intelligence-companies',
      },
      {
        text: 'Top Blockchain Development Companies',
        link: 'https://techreviewer.co/top-blockchain-development-companies',
      },
      {
        text: 'All Ratings',
        link: 'https://techreviewer.co/all-ratings',
      },
    ],
  },
  {
    title: 'About Techreviewer',
    column: '3',
    list: [
      {
        text: 'Contacts',
        link: 'https://techreviewer.co/contact-us',
      },
      {
        text: 'Methodologies we use',
        link: 'https://techreviewer.co/methodologies',
      },
    ],
  },
  {
    title: 'For Buyers',
    column: '3',
    list: [
      {
        text: 'Top companies',
        link:'https://techreviewer.co/all-ratings',
      },
      {
        text: 'All companies',
        link: 'https://techreviewer.co/companies',
      },
      {
        text: 'Industry showcase',
        link: 'https://techreviewer.co/case-studies',
      },
      {
        text: 'Get help with search',
        link: 'https://techreviewer.co/search-for-developer',
      },
    ],
  },
  {
    title: 'For Vendors',
    column: '3',
    list: [
      {
        text: 'Get listed',
        link:'https://techreviewer.co/why-get-listed',
      },
      {
        text: 'Get featured',
        link: 'https://techreviewer.co/become-featured',
      },
      {
        text: 'Widgets page',
        link: 'https://techreviewer.co/widgets',
      },     
      {
        text: 'Write for us',
        link: 'https://techreviewer.co/write-for-us',
      },
    ],
  },
];

const Footer = () => {
  const columns = 3;

  const columnedFooterNav = Array.from({ length: columns }, () => []);

  footerNav.forEach((nav) => {
    const columnIndex = parseInt(nav.column) - 1;
    if (columnIndex >= 0 && columnIndex < columns) {
      columnedFooterNav[columnIndex].push(nav);
    }
  });

  return (
    <React.Fragment>
      <footer className="page-footer no-print">
          <div className="wrapper-content">
            <div className="footer-top">
              <div className="footer-first-column">
              <a href="https://techreviewer.co/" aria-current="page" className="footer-brand">
                <Logo />
              </a>
              <div className="footer-socials">
                <a href="mailto:info@techreviewer.co" target="_blank" rel="noopener noreferrer">

                </a>
                <a href="https://www.linkedin.com/company/techreviewer/" target="_blank" rel="noopener noreferrer">

                </a>
                <a href="https://twitter.com/techreviewer_co" target="_blank" rel="noopener noreferrer">

                </a>
                <a href="https://www.facebook.com/techreviewer.co" target="_blank" rel="noopener noreferrer">

                </a>
              </div>
              <div className="footer-socials">
                <a href="mailto:info@techreviewer.co" target="_blank" rel="noopener noreferrer">
                  <svg className="footer-socials__icon" width="32" height="32">
                    <use xlinkHref="#email" />
                  </svg>
                </a>
                <a href="https://www.linkedin.com/company/techreviewer/" target="_blank" rel="noopener noreferrer">
                  <svg className="footer-socials__icon" width="32" height="32">
                    <use xlinkHref="#linkedin" />
                  </svg>
                </a>
                <a href="https://twitter.com/techreviewer_co" target="_blank" rel="noopener noreferrer">
                  <svg className="footer-socials__icon" width="32" height="32">
                    <use xlinkHref="#twitter" />
                  </svg>
                </a>
                <a href="https://www.facebook.com/techreviewer.co" target="_blank" rel="noopener noreferrer">
                  <svg className="footer-socials__icon" width="32" height="32">
                    <use xlinkHref="#facebook" />
                  </svg>
                </a>
              </div>
              <div className="footer-address">
                <div className="footer-address-text">
                  14 Short Street
                  <br />
                  Manchester, CT 06040, United States
                </div>
              </div>
            </div>
            <div className="footer-nav-column">
              {columnedFooterNav[1].map((nav, index) => (
                <div key={index} className="footer-nav">
                  <div className="footer-nav__title">{nav.title}</div>
                  <ul className="footer-nav__list">
                    {nav.list.map((item, itemIndex) => (
                      <li key={itemIndex} className="footer-nav__item">
                        <a className="footer-nav__link" href={item.link}>
                          {item.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="footer-nav-column">
              {columnedFooterNav[2].map((nav, index) => (
                <div key={index} className="footer-nav">
                  <div className="footer-nav__title">{nav.title}</div>
                  <ul className="footer-nav__list">
                    {nav.list.map((item, itemIndex) => (
                      <li key={itemIndex} className="footer-nav__item">
                        <a className="footer-nav__link" href={item.link}>
                          {item.text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
          <div className="footer-bottom">
            <div className="footer-bottom__copyright">
              Copyright©Techreviewer 2019-{new Date().getFullYear()}
            </div>
            <div className="footer-bottom__links">
              <a className="footer-bottom__link" href="https://techreviewer.co/terms-of-use">
                Terms of use
              </a>
              <a className="footer-bottom__link" href="https://techreviewer.co/privacy-policy">
                Privacy policy
              </a>
            </div>
          </div>
        </div>
        <SvgIcons/>
      </footer>
    </React.Fragment>
  )
}

export default Footer
