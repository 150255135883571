import {
    GET_ORGANIC_TRAFFIC_SUCCESS,
    GET_ORGANIC_TRAFFIC_FAIL,
} from "./actionTypes"

const INIT_STATE = {
    error: "",
    loading: false,
    organicTrafficData: [],
}

const organicTraffic = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ORGANIC_TRAFFIC_SUCCESS:
            return {
                ...state,
                organicTrafficData: action.payload,
            };

        case GET_ORGANIC_TRAFFIC_FAIL:
            return {
                ...state,
                error: action.payload,
            };

        default:
            return state;
    }
}

export default organicTraffic