import {
    GET_USER_SETTINGS,
    GET_USER_SETTINGS_SUCCESS,
    GET_USER_SETTINGS_FAIL,
    POST_CREATE_MY_SETTINGS,
    POST_CREATE_MY_SETTINGS_SUCCESS,
    POST_CREATE_MY_SETTINGS_FAIL,
} from "./actionTypes";

export const getUserSettings = () => ({
    type: GET_USER_SETTINGS,
});

export const getUserSettingsSuccess = data => ({
    type: GET_USER_SETTINGS_SUCCESS,
    payload: data,
});

export const getUserSettingsFail = error => ({
    type: GET_USER_SETTINGS_FAIL,
    payload: error,
});

export const postCreateMySettings = (mySetting) => {
    return {
        type: POST_CREATE_MY_SETTINGS,
        payload: { mySetting },
    }
}

export const postCreateMySettingsSuccess = (mySetting) => ({
    type: POST_CREATE_MY_SETTINGS_SUCCESS,
    payload: mySetting,
})

export const postCreateMySettingsFail = error => ({
    type: POST_CREATE_MY_SETTINGS_FAIL,
    payload: error,
})