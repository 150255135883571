import React, { useEffect, useState } from "react";
import {ModalHeader, Modal} from "reactstrap";
import "./styles.scss"

const LessCompaniesNotification = ({numberOfCompanies, trigger}) => {
    const [subscribemodal, setSubscribemodal] = useState(false);

    useEffect(() => {
        if(numberOfCompanies < 10){
            setSubscribemodal(true)
        }
    }, [trigger]);

    return (
        <div>
            <Modal
                className='modal-window'
                isOpen={subscribemodal}
                role="dialog"
                autoFocus={true}
                centered
                data-toggle="modal"
                toggle={() => {
                    setSubscribemodal(!subscribemodal);
                }}
            >
                <div>
                    <ModalHeader
                        className="border-bottom-0"
                        toggle={() => {
                            setSubscribemodal(!subscribemodal);
                        }}
                    ></ModalHeader>
                </div>
                <div className="modal-body">
                    <div className="text-center mb-4">
                        <div className="row justify-content-center">
                            <div className="col-xl-10 notification-text" style={{ textAlign: 'left' }}>
                                <h4 className="notification-header-text">Please change your filter settings</h4>
                                <p className="font-size-12 mb-4 notification-main-text">
                                  We found a limited number of companies that match your current filters. Choose other filter values to expand the results and get more comprehensive insights.
                                </p>
                                <div className="mb-3 pt-0">
                                    <button className="btn btn-primary notification-ok-button" onClick={() => {setSubscribemodal(false)}}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default LessCompaniesNotification;
