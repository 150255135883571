import React, {useMemo} from 'react';

import Select from 'react-select';

const dropdown_styles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: 'white',
        color: 'gray'
    }),
};

const Dropdown = ({ name, options, value, ...props }) => {
    const formattedOptions = useMemo(() => options ? options.map((value) => {
        if (typeof value === 'object') {
            return value;
        }
        return { value: value, label: value };
    }) : [], [options]);

    const formattedValue = useMemo(() => {
        if (!value) {
            return value;
        }

        return typeof value === 'object' && value.value && value.label ? value : { value, label: value };
    }, [value]);

  return <Select
        classNamePrefix="select"
        name={name}
        options={formattedOptions}
        value={formattedValue}
        {...props}
        styles={dropdown_styles}
      />;
}

export default Dropdown;
