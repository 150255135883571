import React, {useState, useEffect} from 'react'
import { get } from '../../../helpers/api_helper'
import {
    GET_CHARTS_BENCHMARK,
    GET_USER_SETTINGS,
    INITIAL_FILTER_SETTINGS
} from '../../../helpers/url_helper'
import './filters.scss'
import Dropdown from 'components/Common/Dropdown'
import { components } from 'react-select';
import arrowDownIcon from './icons/arrow-down.svg';
import {Card} from "reactstrap";
import MySettings from "../mySettings";
import {employeesNumberOptions, avgHourlyRateOptions, minimumProjectSizeOptions, rateRefactor, employeesRefactor} from "../../../helpers/select_options_helper";
import OrganicTraffic from "../Charts/organicTraffic";
import AuthorityScore from "../Charts/authorityScore";
import ReferringDomains from "../Charts/referringDomains";
import Backlinks from "../Charts/Backlinks";
import AverageRate from "../Charts/AverageRate";
import Employees from "../Charts/Employees";
import MinProjectSize from "../Charts/MinProjectSize";
import OrganicKeywords from "../Charts/organicKeywords";
import LessCompaniesNotification from "../lessCompaniesNotification"
import {useCollapse} from "react-collapsed";

const CompanyFilters = () => {
    const [filtersData, setFiltersData] = useState({})
    const [currentCompany, setCurrentCompany] = useState({})

    const [selectedLine, setSelectedLine] = useState('')
    const [selectedRate, setSelectedRate] = useState('')
    const [selectedEmployees, setSelectedEmployees] = useState('')
    const [selectedSize, setSelectedSize] = useState('')
    const [selectedLocation, setSelectedLocation] = useState('')

    const [periodDataForOrganicTraffic, setPeriodDataForOrganicTraffic] = useState('');
    const [periodDataForAuthorityScore, setPeriodDataForAuthorityScore] = useState('');
    const [periodDataForRefDomains, setPeriodDataForRefDomains] = useState('');
    const [periodDataForOrganicKeywords, setPeriodDataForOrganicKeywords] = useState('');
    const [periodDataForBacklinks, setPeriodDataForBacklinks] = useState('');
    const [periodDataForAverageRate, setPeriodDataForAverageRate] = useState('');
    const [periodDataForEmployees, setPeriodDataForEmployees] = useState('');
    const [periodDataForMinProjectSize, setPeriodDataForMinProjectSize] = useState('');
    const [groupForOrganicRecommendation, setGroupForOrganicRecommendation] = useState('');
    const [groupForAuthorityRecommendation, setGroupForAuthorityRecommendation] = useState('');
    const [groupForRefDomainsRecommendation, setGroupForRefDomainsRecommendation] = useState('');
    const [groupForOrganicKeywordsRecommendation, setGroupForRefOrganicKeywordsRecommendation] = useState('');

    const [numberOfCompaniesTrigger, setNumberOfCompaniesTrigger] = useState(true);
    const [numberOfCompanies, setNumberOfCompanies] = useState(100);

    useEffect(() => {
        fetchInitialSettings();
        fetchChartsData();
    }, []);

    const fetchInitialSettings = async () => {
        try {
            const initialSettingsResponse = await get(INITIAL_FILTER_SETTINGS);

            setCurrentCompany(initialSettingsResponse.data.current_company);
            if(initialSettingsResponse.data.filter_options){
                setFiltersData(initialSettingsResponse.data.filter_options.value);
            }

            if(initialSettingsResponse.data.my_settings === true) {
                setSelectedLine(initialSettingsResponse.data.selected_filter_values.line);
                setSelectedRate(rateRefactor(initialSettingsResponse.data.selected_filter_values.rate));
                setSelectedEmployees(employeesRefactor(initialSettingsResponse.data.selected_filter_values.employees));
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const fetchChartsData = async () => {
        try {
            const chartsResponse = await get(GET_CHARTS_BENCHMARK);
            setPeriodDataForOrganicTraffic(chartsResponse.data.organic_traffic);
            setPeriodDataForAuthorityScore(chartsResponse.data.authority_score);
            setPeriodDataForRefDomains(chartsResponse.data.referring_domains);
            setPeriodDataForBacklinks(chartsResponse.data.backlinks);
            setPeriodDataForOrganicKeywords(chartsResponse.data.organic_keywords)
            setPeriodDataForAverageRate(chartsResponse.data.average_rate);
            setPeriodDataForEmployees(chartsResponse.data.employees);
            setPeriodDataForMinProjectSize(chartsResponse.data.min_project_size);
            if(chartsResponse.data.organic_traffic){
                findUserGroup(chartsResponse.data.organic_traffic, setGroupForOrganicRecommendation)
            }
            if(chartsResponse.data.authority_score){
                findUserGroup(chartsResponse.data.authority_score, setGroupForAuthorityRecommendation)
            }
            if(chartsResponse.data.referring_domains){
                findUserGroup(chartsResponse.data.referring_domains, setGroupForRefDomainsRecommendation)
            }
            if(chartsResponse.data.organic_keywords){
                findUserGroup(chartsResponse.data.organic_keywords, setGroupForRefOrganicKeywordsRecommendation)
            }
            setNumberOfCompanies(chartsResponse.data.analytics_size)
            setNumberOfCompaniesTrigger((value) => !value);
        } catch (error) {
            console.error('Error:', error);
        }
    };

  const Placeholder = props => {
    return (
      <components.Placeholder {...props}>
        <span style={{ color: '#495057' }}>{props.children}</span>
      </components.Placeholder>
    );
  };
  const SingleValue = props => (
    <components.SingleValue {...props}>
      <span style={{ color: '#212A3B' }}>{props.children}</span>
    </components.SingleValue>
  );
  const CustomDropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={arrowDownIcon} alt="arrow-down" />
      </components.DropdownIndicator>
    );
  };
  const handleClickContinue = async () => {
    const params = {
        main_service_line: selectedLine,
        hourly_rate: selectedRate.value,
        employees: selectedEmployees.value,
        min_project_size: selectedSize.value,
        location: selectedLocation.value
    };

    try {
      const chartsResponse = await get(GET_CHARTS_BENCHMARK, { params });

      setPeriodDataForOrganicTraffic(chartsResponse.data.organic_traffic);
      setPeriodDataForAuthorityScore(chartsResponse.data.authority_score);
      setPeriodDataForRefDomains(chartsResponse.data.referring_domains);
      setPeriodDataForBacklinks(chartsResponse.data.backlinks);
      setPeriodDataForOrganicKeywords(chartsResponse.data.organic_keywords);
      setPeriodDataForAverageRate(chartsResponse.data.average_rate);
        setPeriodDataForEmployees(chartsResponse.data.employees);
        setPeriodDataForMinProjectSize(chartsResponse.data.min_project_size);
        if(chartsResponse.data.organic_traffic){
            findUserGroup(chartsResponse.data.organic_traffic, setGroupForOrganicRecommendation)
        }
        if(chartsResponse.data.authority_score){
            findUserGroup(chartsResponse.data.authority_score, setGroupForAuthorityRecommendation)
        }
        if(chartsResponse.data.referring_domains){
            findUserGroup(chartsResponse.data.referring_domains, setGroupForRefDomainsRecommendation)
        }
        if(chartsResponse.data.organic_keywords){
          findUserGroup(chartsResponse.data.organic_keywords, setGroupForRefOrganicKeywordsRecommendation)
        }
        setNumberOfCompanies(chartsResponse.data.analytics_size)
        setNumberOfCompaniesTrigger((value) => !value);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const setDefault = async () => {
      const response = await get(GET_USER_SETTINGS);
      if(response.data.line) {
          setSelectedLine(response.data.line);
          setSelectedRate(rateRefactor(response.data.rate));
          setSelectedEmployees(employeesRefactor(response.data.employees));
          setSelectedSize(response.data.size);
          setSelectedLocation(response.data.location);
      }
  }
  
  const handleChangeWithCheck = (selectedValue, setValue, defaultValue="") => {
      return ({value}) => {
          setValue(value === '0' ? defaultValue : value);
      }
  }

  const {line, rate, employees, size, location} = filtersData;
  const dropdownIndicatorStyles = (base, state) => {
    let changes = {
      height: '36px'
    };
    return Object.assign(base, changes);
  };

    const onChangeEmployeesNumber = selected => {
        setSelectedEmployees(selected);
    };

    const onChangeAvgHourlyRate = selected => {
        setSelectedRate(selected);
    };

    const onChangeMinProjectSize = selected => {
        setSelectedSize(selected.value === '0' ? '' : selected);
    };

    const onChangeLocation = selected => {
        setSelectedLocation(selected.value === 'Clear..' ? '' : selected)
    };

    const findUserGroup = (chartValues, setRecommendation) => {
        for (let i = 0; i < chartValues[1].data.length; i++){
            if(chartValues[1].data[i].y){
                setRecommendation(chartValues[1].data[i].x)
            }
        }
    }

    useEffect(() => {
        const handleNodeRemoval = (e) => {
            if (e[0].removedNodes.length) {
                let mySettingDiv = document.getElementById('my-setting-modal');
                let filtersContainer = document.getElementById('filters_block');

                if (!mySettingDiv) {
                    let newElement = document.createElement('div');
                    newElement.id = 'my-setting-modal';
                    filtersContainer.appendChild(newElement);
                }

                setTimeout(() => {
                    fetchInitialSettings();
                    fetchChartsData();
                }, 500);
            }
        };

        const filtersBlock = document.getElementById("filters_block");
        const removalWatcher = new MutationObserver(handleNodeRemoval);

        if (filtersBlock) {
            removalWatcher.observe(filtersBlock, { childList: true });
        }

        return () => {
            if (filtersBlock) {
                removalWatcher.disconnect();
            }
        };
    }, [fetchInitialSettings, fetchChartsData]);

    const [isExpanded, setExpanded] = useState(true)
    const { getCollapseProps, getToggleProps } = useCollapse({ isExpanded })

return (
    <div>
        <Card>
        <div className="filters_content" id="filters_block">
          <div className="filters_header">
            <div className="filters_title">Filters</div>
          </div>
          <div className="filters_table_wrapper">
              <div className="less_more_filters" {...getToggleProps({
                  onClick: () => setExpanded((prevExpanded) => !prevExpanded),
              })}>
                  {isExpanded ? (<div style={{fontSize: 16, fontWeight: 500}}>Less filters<div className="uncollapsed"></div></div>) : (<div style={{fontSize: 16, fontWeight: 500}}>More filters<div className="collapsed"></div></div>)}
              </div>
              <div {...getCollapseProps()}>
                  <table className="filters_table">
                      <tbody>
                      <tr>
                          <td>
                              <Dropdown
                                  components={{
                                      SingleValue: SingleValue,
                                      Placeholder: Placeholder,
                                      DropdownIndicator: CustomDropdownIndicator,
                                      IndicatorSeparator: () => null,
                                  }}
                                  className="filters-dropdown"
                                  name="line"
                                  placeholder="Service line"
                                  value={selectedLine}
                                  options={line}
                                  onChange={handleChangeWithCheck(selectedLine, setSelectedLine)}
                                  isSearchable={false}
                              />
                          </td>
                          <td className="filters_td">
                              <Dropdown
                                  components={{
                                      SingleValue: SingleValue,
                                      Placeholder: Placeholder,
                                      DropdownIndicator: CustomDropdownIndicator,
                                      IndicatorSeparator: () => null,
                                  }}
                                  className="filters-dropdown"
                                  name="rate"
                                  placeholder="Hourly rate"
                                  value={selectedRate}
                                  options={avgHourlyRateOptions}
                                  onChange={onChangeAvgHourlyRate}
                                  isSearchable={false}
                              />
                          </td>
                          <td className="filters_td">
                              <Dropdown
                                  components={{
                                      SingleValue: SingleValue,
                                      Placeholder: Placeholder,
                                      DropdownIndicator: CustomDropdownIndicator,
                                      IndicatorSeparator: () => null,
                                  }}
                                  className="filters-dropdown"
                                  name="employees"
                                  placeholder="Employees"
                                  value={selectedEmployees}
                                  options={employeesNumberOptions}
                                  onChange={onChangeEmployeesNumber}
                                  isSearchable={false}
                              />
                          </td>
                          <td className="filters_td">
                              <Dropdown
                                  components={{
                                      SingleValue: SingleValue,
                                      Placeholder: Placeholder,
                                      DropdownIndicator: CustomDropdownIndicator,
                                      IndicatorSeparator: () => null,
                                  }}
                                  className="filters-dropdown"
                                  name="size"
                                  placeholder="Min. project size"
                                  value={selectedSize}
                                  options={minimumProjectSizeOptions}
                                  onChange={onChangeMinProjectSize}
                                  isSearchable={false}
                              />
                          </td>
                          <td className="filters_td">
                              <Dropdown
                                  components={{
                                      SingleValue: SingleValue,
                                      Placeholder: Placeholder,
                                      DropdownIndicator: CustomDropdownIndicator,
                                      IndicatorSeparator: () => null,
                                  }}
                                  className="filters-dropdown"
                                  name="location"
                                  placeholder="Location"
                                  value={selectedLocation}
                                  options={location}
                                  onChange={onChangeLocation}
                                  isSearchable
                              />
                          </td>
                      </tr>
                      </tbody>
                  </table>
              </div>
              </div>
              <div style={{padding: "20px", height: "37%", textAlign: "right"}}>
                  <button onClick={setDefault}  className="filters_reset_btn">Set to default</button>
                  <button onClick={handleClickContinue} className="filters_update_btn">Update charts</button>
                  <MySettings/>
              </div>
              <div id="my-setting-modal"></div>
            </div>
        </Card>
        <section id="organic-traffic">
          <OrganicTraffic customPeriod={periodDataForOrganicTraffic} userGroup={groupForOrganicRecommendation} companyName={currentCompany.name}/>
        </section>
        <section id="organic-keywords">
          <OrganicKeywords customPeriod={periodDataForOrganicKeywords} userGroup={groupForOrganicKeywordsRecommendation} companyName={currentCompany.name}/>
        </section>
        <section id="authority-score">
          <AuthorityScore customPeriod={periodDataForAuthorityScore} userGroup={groupForAuthorityRecommendation} companyName={currentCompany.name}/>
        </section>
        <section id="referring-domains">
          <ReferringDomains customPeriod={periodDataForRefDomains} userGroup={groupForRefDomainsRecommendation} companyName={currentCompany.name}/>
        </section>
        <section id="backlinks">
          <Backlinks customPeriod={periodDataForBacklinks} companyName={currentCompany.name}/>
        </section>
        <section id="average-rate">
          <AverageRate customPeriod={periodDataForAverageRate} companyName={currentCompany.name}/>
        </section>
        <section id="employees">
          <Employees customPeriod={periodDataForEmployees} companyName={currentCompany.name}/>
        </section>
        <section id="min-project-size">
          <MinProjectSize customPeriod={periodDataForMinProjectSize} companyName={currentCompany.name}/>
        </section>
        <LessCompaniesNotification numberOfCompanies={numberOfCompanies} trigger={numberOfCompaniesTrigger} />
    </div>
)}

export default CompanyFilters
