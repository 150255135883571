import PropTypes from "prop-types";
import React from "react";

//i18n
import { withTranslation } from "react-i18next";

import CompanyFilters from "../../components/Common/CompanyFilters/companyFilters";
import NavigationBar from "../../components/Common/NavigationBar/navigationBar";
import '../../components/Common/NavigationBar/navigationBar.scss';
import './styles.scss';

const Analytics = props => {

    //meta title
    document.title = "Techreviewer.co – Analytics Benchmarks";

    return (
      <React.Fragment>
          <div className="page-content">
            <div className="analytic-title-mobile">
              <h className="analytic-title-mobile-text">Competitive</h>
              <h className="analytic-title-mobile-text">Analysis</h>
            </div>
            <NavigationBar/>
            <div className="content-container">
              <div className="navigation-content">
                <p className="title analytic-title">Competitive Analysis</p>
                <CompanyFilters/>
              </div>
            </div>
          </div>
      </React.Fragment>
  );
};

Analytics.propTypes = {
    t: PropTypes.any,
};

export default withTranslation()(Analytics);