import React from "react";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Analytics
import Analytics from "../pages/Analytics/index";
import ExternalLogin from "../pages/Authentication/ExternalLogin";

const authProtectedRoutes = [
  //Analytics
  { path: "/analytics", component: <Analytics /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Analytics />,
  },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/external", component: <ExternalLogin /> },
];

export { authProtectedRoutes, publicRoutes };
